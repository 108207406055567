import React, { useState } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import HeroSplit from "../components/Hero/HeroSplit";
import Services from "../components/Repeating/Services";
import TestimonialBackgroundImage from "../components/Testimonial/TestimonialBackgroundImage";
import WhyUs from "../components/Repeating/WhyUs";
import CTABackgroundImage from "../components/CTA/CTABackgroundImage";
import ModalGallery from "../components/Modal/ModalGallery";
import IndustriesSidebar from "../components/Repeating/IndustriesSidebar";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
   const [slideIndex, setSlideIndex] = useState(0);

   return (
      <Layout headerStyle="overlap" headerLinkColor="white" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Fiberglass Medical Equipment Manufacturer | Painted Rhino"
            description="When you need top quality fiberglass medical equipment from a trusted manufacturer, choose the team of dedicated experts at Painted Rhino."
            // openGraphImage={data.openGraphImage.publicURL}
            // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <HeroSplit
            hasContainer={false}
            backgroundImageWrapperWidth="1/2"
            textWrapperWidth="1/2"
            backgroundImages={data.heroDesktop.childImageSharp.fluid}
            backgroundImageSide="right"
            backgroundSizeDesktop="cover"
            backgroundSizeMobile="cover"
            backgroundPositionDesktop="50% 50%"
            backgroundPositionMobile="50% 50%"
            minHeightDesktop="704px"
            minHeightMobile="330px"
         >
            <h1>Fiberglass Medical Equipment Manufacturing</h1>
            <p>
               When you need quality medical equipment from a trusted manufacturer, choose Painted Rhino. We’re a family-owned business with extensive
               industry expertise and a strong focus on innovation and creative solutions. You bring us your item’s design, and we’ll make it. Our
               team can also manufacture a prototype and even aid in the design so that you can test a concept.
            </p>
            <ButtonSolid as="button" data="modal-contact" text="Get a Quote" className="w-full md:w-auto" />
         </HeroSplit>

         <section className="pb-18 md:pb-36">
            <div className="container">
               <div className="grid gap-y-12 md:grid-cols-12 md:gap-x-10 lg:gap-x-20">
                  <div className="md:col-span-8 md:col-start-1">
                     <div className="mb-12 md:mb-16">
                        <h2>Your Premier Production Team</h2>
                        <p>
                           We're experts in manufacturing a wide range of fiberglass medical equipment, from fiberglass housing for MRI machines (like
                           housing we made for the innovators at{" "}
                           <a href="https://q.bio/" target="_blank" rel="noreferrer">
                              Q bio
                           </a>
                           ) to incubators for newborns, wheelchair ramps, and more. Our team of production specialists expertly moves from
                           specifications to delivery, so you get quality products, and medical teams and patients ultimately have what they need.
                        </p>
                     </div>

                     <h3>What We Can Produce</h3>
                     <p>
                        Our team has decades of experience in <a href="https://www.paintedrhino.com/vacuum-forming-thermoforming-company/" className="text-[#006ee8]">vacuum forming</a> and <a href="https://www.paintedrhino.com/fiberglass-composite-parts-manufacturing/" className="text-[#006ee8]">fiberglass composite</a> manufacturing. We offer a broad scope of
                        top-quality medical equipment production that includes, but is not limited to:
                     </p>
                     <ul className="styled-list mb-6">
                        <li>MRI housing</li>
                        <li>Foot spas</li>
                        <li>Hydro therapy tubs</li>
                        <li>Walk-in tubs</li>
                        <li>Chairlifts</li>
                        <li>Wheelchair ramps</li>
                        <li>Medical equipment covers</li>
                        <li>Newborn incubators</li>
                        <li>Newborn holding beds</li>
                        <li>Ambulance parts</li>
                        <li>Medical transport parts</li>
                        <li>Float spa for hydrotherapy/recovery</li>
                     </ul>

                     <p>Did you know? Fiberglass is an excellent material for MRI machines, where there can’t be any metal near magnetic fields.</p>
                  </div>
                  <div className="md:col-end-13 lg:col-span-4">
                     <IndustriesSidebar />
                  </div>
               </div>
            </div>
         </section>

         <section className="pb-20 md:pb-32">
            <div className="container">
               <div className="grid gap-2 md:grid-cols-2 md:gap-4">
                  <div className="grid grid-cols-10 grid-rows-2 gap-2 md:gap-4">
                     <div className="col-span-6 col-start-1 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(0)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="h-full w-full"
                              fluid={data.galleryThumbnail1.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-span-4 col-end-11 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(1)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="h-full w-full"
                              fluid={data.galleryThumbnail2.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-span-4 col-start-1 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(4)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="h-full w-full"
                              fluid={data.galleryThumbnail5.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-span-6 col-end-11 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(3)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="h-full w-full"
                              fluid={data.galleryThumbnail4.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                  </div>
                  <div className="grid grid-flow-col grid-cols-10 grid-rows-2 gap-2 md:gap-4">
                     <div className="col-span-4 row-span-2">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(2)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="h-full w-full"
                              fluid={data.galleryThumbnail3.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-span-6 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(5)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="h-full w-full"
                              fluid={data.galleryThumbnail6.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-span-6 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(6)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="h-full w-full"
                              fluid={data.galleryThumbnail7.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <WhyUs className="pb-20 md:pb-28" contentAlignment="md:text-center md:mx-auto" headerHeading="The Painted Rhino Difference." />

         <TestimonialBackgroundImage />

         <Services
            className="mb-22 pt-20 md:mb-32 md:pt-32"
            headerHeading="Our Manufacturing Processes"
            headerText="At Painted Rhino, you bring us your design and we’ll manufacture it. We’re experts in:"
         />

         <CTABackgroundImage />

         <ModalGallery slideIndex={slideIndex}>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox1.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox2.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox3.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox4.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox5.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox6.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox7.childImageSharp.fluid} alt="" />
            </div>
         </ModalGallery>
      </Layout>
   );
};

export default Page;

export const query = graphql`
   {
      heroDesktop: file(relativePath: { eq: "industries/Medical/1.0- Hero-Medical.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 800) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      galleryThumbnail1: file(relativePath: { eq: "industries/Medical/Gallery-Thumbnails/Medical-1.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 355) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox1: file(relativePath: { eq: "industries/Medical/Gallery-Lightbox/1.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail2: file(relativePath: { eq: "industries/Medical/Gallery-Thumbnails/Medical-2.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 221) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox2: file(relativePath: { eq: "industries/Medical/Gallery-Lightbox/2.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail3: file(relativePath: { eq: "industries/Medical/Gallery-Thumbnails/Medical-3.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 222) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox3: file(relativePath: { eq: "industries/Medical/Gallery-Lightbox/3.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail4: file(relativePath: { eq: "industries/Medical/Gallery-Thumbnails/Medical-4.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 354) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox4: file(relativePath: { eq: "industries/Medical/Gallery-Lightbox/4.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail5: file(relativePath: { eq: "industries/Medical/Gallery-Thumbnails/Medical-5.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 222) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox5: file(relativePath: { eq: "industries/Medical/Gallery-Lightbox/5.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail6: file(relativePath: { eq: "industries/Medical/Gallery-Thumbnails/Medical-6.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 354) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox6: file(relativePath: { eq: "industries/Medical/Gallery-Lightbox/6.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail7: file(relativePath: { eq: "industries/Medical/Gallery-Thumbnails/Medical-7.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 354) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox7: file(relativePath: { eq: "industries/Medical/Gallery-Lightbox/7.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
   }
`;
